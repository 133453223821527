<template>
  <div class="page">
    <Navbar title="教育修改" type="SMP" />
    <van-form @submit="submit" class="form">
      <van-cell-group>
        <van-field
          v-model="edu.school"
          label="学校名称"
          placeholder="请输入学校名称"
          show-word-limit
          :rules="[{ required: true, message: '请输入学校名称' }]"
        />
        <van-field
          v-model="edu.qualifyName"
          label="学历类型"
          placeholder="请选择学校类型"
          @click="qualifyShow = true"
          show-word-limit
          :rules="[{ required: true, message: '请选择学历类型' }]"
        />
        <van-popup :show="qualifyShow" position="bottom">
          <van-picker
            :columns="qualifys"
            :columns-field-names="{
              text: 'name',
            }"
            @cancel="qualifyShow = false"
            @confirm="qualifyConfirm"
        /></van-popup>
        <van-field
          v-model="edu.startDate"
          name="开始日期"
          label="开始日期"
          @click="startDateShow = true"
          placeholder="请选择开始日期"
          :rules="[{ required: true, message: '请选择开始日期' }]"
        />
        <van-popup :show="startDateShow" position="bottom">
          <van-datetime-picker
            v-model="startCurrentDate"
            type="year-month"
            title="选择年月"
            @confirm="startDateConfirm"
            @cancel="startDateShow = false"
            :min-date="minDate"
            :max-date="maxDate"
        /></van-popup>
        <van-field
          v-model="edu.endDate"
          name="结束日期"
          label="结束日期"
          @click="endDateShow = true"
          placeholder="请选择开始日期"
          :rules="[{ required: true, message: '请选择开始日期' }]"
        />
        <van-popup :show="endDateShow" position="bottom">
          <van-datetime-picker
            v-model="endCurrentDate"
            type="year-month"
            title="选择年月"
            @confirm="endDateConfirm"
            @cancel="endDateShow = false"
            :min-date="minDate"
            :max-date="maxDate"
        /></van-popup>
      </van-cell-group>
      <van-row>
        <van-col offset="4" span="16" class="subbtn">
          <van-button
            icon="passed"
            size="small"
            color="#00CC99"
            class="btn"
            native-type="submit"
          >
            提交保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-popup :show="loadingShow" style="background-color: transparent"
      ><van-loading size="24px" :color="COLOR" vertical>加载中...</van-loading>
    </van-popup>
  </div>
</template>
<script>
import { Icon, Tag, Form, CellGroup, Field, Popup, DatetimePicker, Picker, Loading } from 'vant'
import Navbar from '../../common/Navbar.vue'
export default {
  components: {
    Navbar: Navbar,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Form.name]: Form,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Picker.name]: Picker,
    [Loading.name]: Loading
  },
  data () {
    return {
      seq: '',
      loadingShow: false,
      qualifyShow: false,
      startDateShow: false,
      startCurrentDate: new Date(),
      endDateShow: false,
      endCurrentDate: new Date(),
      edu: { school: '', qualifyName: '', qualifyCode: '', startDate: '', endDate: '' },
      qualifys: [{ code: '', name: '未选择' }],
      minDate: new Date(1980, 0, 1),
      maxDate: new Date()
    }
  },
  mounted () {
    var query = this.$route.query
    this.personCode = query.personCode
    this.seq = query.seq
    this.retrieveQualifyOption()
    this.retrieveEdu()
  },
  methods: {
    qualifyConfirm (val) {
      console.log(val)
      this.edu.qualifyCode = val.code
      this.edu.qualifyName = val.name
      this.qualifyShow = false
    },
    startDateConfirm (time) {
      console.log(time)
      var year = time.getFullYear()
      var month = time.getMonth() + 1
      var t = year + '-' + (month < 10 ? '0' + month : month)
      this.edu.startDate = t
      this.startDateShow = false
    },
    endDateConfirm (time) {
      console.log(time)
      var year = time.getFullYear()
      var month = time.getMonth() + 1
      var t = year + '-' + (month < 10 ? '0' + month : month)
      this.edu.endDate = t
      this.endDateShow = false
    },
    async retrieveEdu () {
      var pd = { personCode: this.personCode, key: 'PSN_EDU_ITEM', type: 'DTL', seq: this.seq }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        var content = JSON.parse(res.data.content)
        this.edu.school = content.school
        this.edu.qualifyName = content.qualifyName
        this.edu.qualifyCode = content.qualifyCode
        this.edu.startDate = content.startDate
        this.edu.endDate = content.endDate
      }
    },
    async retrieveQualifyOption () {
      var pd = { key: 'QUALIFY_TYPE' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/option/retrieveOptions', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.qualifys = this.qualifys.concat(res.data.item)
      }
    },
    async submit () {
      var attrs = [
        { key: 'PSN_EDU_ITEM', content: JSON.stringify(this.edu), type: 'DTL', seq: this.seq }
      ]
      var pd = { personCode: this.personCode, attrs: JSON.stringify(attrs) }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/editPersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$router.go(-1)
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '编辑失败:' + res.msg
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.form {
  padding: 0px 5px;
  .btn {
    margin-top: 10px;
  }
}
</style>
